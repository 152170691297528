import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const DomainChecker = () => {
  const { t } = useTranslation();
  const [result, setResult] = useState(null);
  const [domain, setDomain] = useState("");
  const [domainData, setDomainData] = useState(null); // To store domain and availability data
  const [error, setError] = useState(null); // To store error information

  const handleChange = (e) => {
    const value = e.target.value;
    setDomain(value);
  };

  const makeApiCall = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const DOMAIN_LIST = domain; // Replace with the domains you want to check
    setError();

    try {
      const response = await fetch(
        `http://localhost:5000/api/check-domain?domains=${DOMAIN_LIST}`
      );

      const xmlData = await response.text();
      setResult(xmlData);
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlData, "text/xml");

      const domainCheckResult = xmlDoc.querySelector("DomainCheckResult");
      if (domainCheckResult) {
        const domain = domainCheckResult.getAttribute("Domain");
        const available = domainCheckResult.getAttribute("Available");
        const isPremiumName = domainCheckResult.getAttribute("IsPremiumName");
        const premiumRegistrationPrice = domainCheckResult.getAttribute(
          "PremiumRegistrationPrice"
        );
        const description = domainCheckResult.getAttribute("Description");
        const status = domainCheckResult.getAttribute("ApiResponse Status=");

        // Update the state with the extracted data
        setDomainData({
          domain,
          available,
          isPremiumName,
          premiumRegistrationPrice,
          description,
          status,
        });
      } else {
        // Handle error responses
        setError(xmlDoc.querySelector("Error").textContent);
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setResult("Error fetching data from the server.");
    }
  };

  return (
    <div>
      <form>
        <h2>Domain Checker</h2>
        <input
          type="domain"
          name="domain"
          onChange={handleChange}
          value={domain}
          id=""
          placeholder={t("Serach for Domains ...")}
          required
        />
        <button type="button" onClick={makeApiCall}>
          Lookup
        </button>
      </form>

      {domainData && (
        <div>
          <h3>Domain Information</h3>
          <p>Domain: {domainData.domain}</p>
          <p>Availability: {domainData.available}</p>

          <p>Error: {error}</p>

          {domainData.available === "true" &&
            domainData.isPremiumName === "false" && (
              <div>
                <p>Price: HK$180/year</p>
                <button>Buy Now</button>
              </div>
            )}

          {domainData.available === "true" &&
            domainData.isPremiumName === "true" && (
              <div>
                <p>Price: {domainData.premiumRegistrationPrice} HKD/year</p>
                <button>Buy This</button>
              </div>
            )}

          <h3>Raw Response</h3>
          <pre className="xml-response">{result}</pre>
        </div>
      )}
    </div>
  );
};

export default DomainChecker;
