import React from "react";
import "../App.css";
import Aboutuscontent from "../components/Aboutuscontent";
import Footer from "../components/Footer";
import AbboutusBanner from "../components/AboutusBanner";
import { Helmet } from "react-helmet-async";

function AboutusPage() {
  return (
    <>
      <Helmet>
        <title>KW Informatics|About Us</title>
        <meta
          name="description"
          content="At KW Informatics, we are dedicated to delivering exceptional tailor-made IT solutions that empower businesses to grow."
        />
        <link rel="canonical" href="/aboutus" />
      </Helmet>
      <AbboutusBanner />
      <Aboutuscontent />
      <Footer />
    </>
  );
}

export default AboutusPage;
