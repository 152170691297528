import "../App.css";
import "./Homebanner.css";
import { useTranslation } from "react-i18next";

function Solutionsbanner() {
  const { t } = useTranslation();
  return (
    <div className="hero-container-solutions">
      <h1>{t("Solutions and Services")}</h1>
    </div>
  );
}

export default Solutionsbanner;
