import { useTranslation } from "react-i18next";
import "../App.css";
import "./Homebanner.css";

function ContactusBanner() {
  const { t } = useTranslation();
  return (
    <div className="hero-container-contactus">
      <h1>{t("Contact Us")}</h1>
    </div>
  );
}

export default ContactusBanner;
