import React from "react";
import "./TranslabtorCards.css";
import TranslabtorCardItem from "./TranslabtorCardItem";
import imgHome from "../images/TransLabtor01Home.png";
import imgConfig from "../images/TransLabtor02Config.png";
import imgLog from "../images/TransLabtor03Log.png";
import imgResult from "../images/TransLabtor04ResultManagement.png";
import { useTranslation } from "react-i18next";

function TranslabtorCards() {
  const { t } = useTranslation();

  return (
    <>
      <div className="tcards">
        <div className="tcards__container">
          <div className="tcards__wrapper">
            <ul className="tcards__items">
              <TranslabtorCardItem
                src={imgHome}
                text={t(
                  "Monitor your system's connectivity in real-time with our intuitive status screen. Gain insights into the health of your connections, including the current status, message counts, error counts, and the timestamp of the last transaction. Take control of your connections by toggling them ON or OFF directly from this screen."
                )}
                label={t("Status Screen")}
              />
            </ul>
            <ul className="tcards__items">
              <TranslabtorCardItem
                src={imgConfig}
                text={t(
                  "The Configuration screen allows you to tailor how your devices connect to TransLABtor such as through file transfers or network communication. Configure your message protocols, including popular formats like ASTM and HL7, to seamlessly integrate your devices."
                )}
                label={t("Configuration Screen")}
              />
            </ul>
            <ul className="tcards__items">
              <TranslabtorCardItem
                src={imgLog}
                text={t(
                  "The Log screen acts as audit trail. It captures key events including login and logout activities, configuration changes, and log raw message flow through the systems. This powerful feature ensures transparency and accountability to fulfill the audit requirements."
                )}
                label={t("Log Screen")}
              />
            </ul>
            <ul className="tcards__items">
              <TranslabtorCardItem
                src={imgResult}
                text={t(
                  "The Result Workspace is your command center for you to review and manage results. You can take actions like sending results to your LIS system , rerun tests, or reject results when needed. The flexibility extends to exporting data to third-party applications like Excel. Empower your workflow with the Result Workspace—where every action is at your fingertips for streamlined and effective results management."
                )}
                label={t("Result Workspace")}
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default TranslabtorCards;
