import React from "react";
import "../App.css";
import ContactusBanner from "../components/ContactusBanner";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactusForm";
import { Helmet } from "react-helmet-async";

export const ContactusPage = () => {
  return (
    <>
      <Helmet>
        <title>KW Informatics|Contact Us</title>
        <meta
          name="description"
          content="Leave your message to us. Let us know what IT solutions you are looking for. We are ready to help!"
        />
        <link rel="canonical" href="/contactus" />
      </Helmet>
      <ContactusBanner />
      <ContactForm />
      <Footer />
    </>
  );
};
