import React from "react";
import "../App.css";
import "./Homebanner.css";
import { useTranslation } from "react-i18next";

function Homebanner() {
  const { t } = useTranslation();

  return (
    <div className="hero-container">
      <h1>{t("HomepageHeadline1")}</h1>
      <p>{t("HomepageHeadline2")}</p>
    </div>
  );
}

export default Homebanner;
