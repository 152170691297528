import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./Cards.css";
import { ActiveTabContext } from "./ActiveTabContext";

function CardItem(props) {
  const { activeTab, setActiveTab } = useContext(ActiveTabContext);

  const handleSetActiveTab = () => {
    if (props.path.includes("solutions")) {
      setActiveTab("solutions");
    } else if (
      props.path.includes("products") ||
      props.path.includes("translabtor")
    ) {
      setActiveTab("products");
    }
  };

  return (
    <>
      <li className="cards__item">
        <Link
          className="cards__item__link"
          to={props.path}
          onClick={(e) => {
            handleSetActiveTab();
          }}
        >
          <figure className="cards__item__pic-wrap" data-category={props.label}>
            <img className="cards__item__img" alt="Images" src={props.src} />
          </figure>
          <div className="cards__item__info">
            <h5 className="cards__item__text">{props.text}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
