import React from "react";
import "../App.css";
import Footer from "../components/Footer";
import TranslabtorBanner from "../components/TranslabtorBanner";
import TranslabtorCards from "../components/TranslabtorCards";
import { Helmet } from "react-helmet-async";

function TransLABtorPage() {
  return (
    <>
      <Helmet>
        <title>KW Informatics|TransLABtor|Your laboratory translator.</title>
        <meta
          name="description"
          content="TransLABtor is a middleware helps connecting laboratory instruments to Laboratory Information System (LIS)"
        />
        <link rel="canonical" href="/translabtor" />
      </Helmet>
      <TranslabtorBanner />
      <TranslabtorCards />
      <Footer />
    </>
  );
}

export default TransLABtorPage;
