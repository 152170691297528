import { useTranslation } from "react-i18next";
import "../App.css";
import "./Homebanner.css";

function AbboutusBanner() {
  const { t } = useTranslation();
  return (
    <div className="hero-container-aboutus">
      <p>{t("About Us")}</p>
    </div>
  );
}

export default AbboutusBanner;
