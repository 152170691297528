import { useTranslation } from "react-i18next";
import "../App.css";
import "./Homebanner.css";

function Homebanner() {
  const { t } = useTranslation();

  return (
    <div className="hero-container-products">
      <h1>{t("Products")}</h1>
    </div>
  );
}

export default Homebanner;
