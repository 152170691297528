import React from "react";
import "./Aboutuscontent.css";
import { useTranslation } from "react-i18next";

function Aboutuscontent() {
  const { t } = useTranslation();

  return (
    <>
      <div className="content-container">
        <div className="about-section">
          <h2>{t("About KW Informatics")}</h2>
          <p>{t("AboutKWInformaticsDescription")}</p>
        </div>

        <div className="vision-section">
          <h2>{t("Our Vision")}</h2>
          <p>{t("OurVisionDescription")}</p>
        </div>
        <div className="why-choose-section">
          <h2>{t("Why Choose KW Informatics")}</h2>
          <ul>
            <li>
              <strong>{t("Expertise")}:</strong> {t("ExpertiseDescription")}
            </li>
            <li>
              <strong>{t("Client-Centric Approach")}:</strong>
              {t("ClientCentricApproachDescription")}
            </li>
            <li>
              <strong>{t("Innovation")}:</strong> {t("InnovationDescription")}
            </li>
          </ul>
        </div>

        <div className="contactus-section">
          <h2>{t("Contact Us")}</h2>
          <p>{t("ContactUsDescription")}</p>
        </div>
      </div>
    </>
  );
}

export default Aboutuscontent;
