import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import enIcon from "../images/enicon.png";
import zhhkIcon from "../images/HKicon.png";
import zhIcon from "../images/cnicon.png";
import jaIcon from "../images/jpicon.png";
import "./LanguageDropdown.css";

function LanguageDropdown() {
  const { i18n } = useTranslation();

  const languages = [
    { code: "en", name: "English", icon: enIcon },
    { code: "zh_hk", name: "繁體中文", icon: zhhkIcon },
    { code: "zh", name: "简体中文", icon: zhIcon },
    { code: "ja", name: "日本語", icon: jaIcon },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (code) => {
    setSelectedLanguage(code);
    i18n.changeLanguage(code);
    setIsOpen(false);
  };

  return (
    <div className="lanuguage-container">
      <div className="language-dropdown">
        <div className="select" onClick={() => setIsOpen(!isOpen)}>
          <img
            src={languages.find((l) => l.code === selectedLanguage)?.icon}
            width="25"
            height="20"
            alt="Current Language"
          />
          <span className={`arrow ${isOpen ? "open" : ""}`}></span>
        </div>
        {isOpen && (
          <div className="language-options">
            {languages.map((language) => (
              <div
                key={language.code}
                onClick={() => handleLanguageChange(language.code)}
              >
                <img src={language.icon} alt={language.name} />
                {language.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default LanguageDropdown;
