import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation from react-i18next
import "./ContactusForm.css"; // Include your CSS file for styling
import imgMail from "../images/mail.png";
import emailjs from "@emailjs/browser";

function ContactForm() {
  const { t } = useTranslation(); // Initialize the t function for translations
  const form = useRef();
  const [data, setData] = useState({ name: "", email: "", message: "" });
  const [inquiryType, setInquiryType] = useState("general");
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  const [messageSent, setMessageSent] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_t8qt98i",
        "template_7nylkl2",
        form.current,
        "bwU6ObNEt49uJx80p"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessageSent(result.text);
        },
        (error) => {
          console.log(error.text);
          setMessageSent(error.text);
        }
      );

    setData({ name: "", email: "", message: "" });
    setInquiryType("general");
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <h2>{t("Leave us a message!")}</h2>
        <input
          type="text"
          name="name"
          id=""
          onChange={handleChange}
          value={data.name}
          placeholder={t("Enter your name (required)")}
          required
        />
        <input
          type="email"
          name="email"
          id=""
          onChange={handleChange}
          value={data.email}
          placeholder={t("example@yourcompany.com (required)")}
          required
        />
        <select
          name="inquiryType"
          value={inquiryType}
          onChange={(e) => {
            const inquiryType = e.target.value;
            setInquiryType(inquiryType);
          }}
        >
          <option value="general">{t("General Inquiry")}</option>
          <option value="website">{t("Website Development")}</option>
          <option value="application">{t("Software Development")}</option>
          <option value="itsupport">{t("IT Services")}</option>
          <option value="translabtor">{t("TransLABtor")}</option>
        </select>
        <textarea
          name="message"
          id=""
          onChange={handleChange}
          value={data.message}
          cols="30"
          rows="10"
          placeholder={t("type here...")}
        />
        <button type="submit">{t("Submit")}</button>
        {messageSent === "OK" && <div>{t("Message sent!")}</div>}
      </form>
      <div className="contact-form-container-contactus">
        <div class="footer-link-items-contactus">
          <a href={"mailto:support@kwinformatics.com"}>
            <img src={imgMail} className="footer-logo" alt="EMail" />
            {t("Technical Support")}
          </a>
          <a href={"mailto:sales@kwinformatics.com"}>
            <img src={imgMail} className="footer-logo" alt="EMail" />
            {t("Sales Inquiry")}
          </a>
          <a href={"mailto:distributors@kwinformatics.com"}>
            <img src={imgMail} className="footer-logo" alt="EMail" />
            {t("Distributors")}
          </a>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
