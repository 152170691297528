import React from "react";
import "../App.css";
import Solutionsbanner from "../components/SolutionsBanner";
import SolutionsCards from "../components/SolutionsCards";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

function SolutionsPage() {
  return (
    <>
      <Helmet>
        <title>
          KW Informatics|Solutions|IT Project Management, Procurement,
          Consultation
        </title>
        <meta
          name="description"
          content="We can assist with CRM System, ERP System, Inventory System, Websites, Web Apps, Online Store, API Proficiency, SEO."
        />
        <link rel="canonical" href="/solutions" />
      </Helmet>
      <Solutionsbanner />
      <SolutionsCards />
      <Footer />
    </>
  );
}

export default SolutionsPage;
