import React from "react";
import "../App.css";
import ProductsBanner from "../components/ProductsBanner";
import Footer from "../components/Footer";
import ProductsCards from "../components/ProductsCards";
import { Helmet } from "react-helmet-async";

function ProductsPage() {
  return (
    <>
      <Helmet>
        <title>
          KW Informatics|Products|ERP Systems, CRM Systems, Online Store, API
          Programs, TransLABtor.
        </title>
        <meta
          name="description"
          content="We can assist with CRM System, ERP System, Inventory System, Websites, Web Apps, Online Store, API Programs, SEO."
        />
        <link rel="canonical" href="/products" />
      </Helmet>
      <ProductsBanner />
      <ProductsCards />
      <Footer />
    </>
  );
}

export default ProductsPage;
