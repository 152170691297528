import "../App.css";
import "./Homebanner.css";
import { useTranslation } from "react-i18next";
import imgKeyFeature from "../images/TransLabtorKeyFeature.png";

function TranslabtorBanner() {
  const { t } = useTranslation();

  return (
    <>
      {" "}
      <div className="hero-container-translabtor"></div>{" "}
      <h1>
        {t(
          "TransLABtor is a middleware solution to fulfill the connectivity needs for laboratories."
        )}
      </h1>
      <h1 style={{ marginTop: "60px" }}>{t("Key Features")}</h1>
      <div className="translabtorfeatures-container">
        <img
          src={imgKeyFeature}
          className="translabtorfeatures"
          alt="TransLABtor Key Features"
        />
      </div>
    </>
  );
}

export default TranslabtorBanner;
