import React from "react";
import "./Cards.css";
import OtherCardItem from "./OtherCardItem";
import imgTransLABtor from "../images/TransLabtorCard.png";
import imgReportFinder from "../images/ReportFinder.png";
import { useTranslation } from "react-i18next";

function ProductsCards() {
  const { t } = useTranslation();
  return (
    <>
      <div className="othercards">
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <OtherCardItem
                src={imgTransLABtor}
                text={t("TransLABtordes")}
                path="/translabtor"
              />
              <OtherCardItem
                src={imgReportFinder}
                text={t("ReportFinderdes")}
                path="/products"
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsCards;
