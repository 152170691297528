import React, { useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import imgCall from "../images/call.png";
import imgMail from "../images/mail.png";
import imgWhatsapp from "../images/whatsapp.png";
import { ActiveTabContext } from "./ActiveTabContext";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const { activeTab, setActiveTab } = useContext(ActiveTabContext);

  return (
    <>
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          {t("Learn more about our offering")}
        </p>
        <Button buttonStyle="btn--outline">{t("Contact Us")}</Button>
      </section>
      <div className="footer-container">
        <div class="footer-links">
          <div className="footer-link-wrapper">
            <div class="footer-link-items">
              <h2>{t("About Us")}</h2>
              <Link
                to="/aboutus"
                onClick={(e) => {
                  setActiveTab("aboutus");
                }}
              >
                {t("Who We Are")}
              </Link>
            </div>
            <div class="footer-link-items">
              <h2>{t("Products")}</h2>
              <Link
                to="/translabtor"
                onClick={(e) => {
                  setActiveTab("products");
                }}
              >
                TransLABtor
              </Link>
              <Link
                to="/products"
                onClick={(e) => {
                  setActiveTab("products");
                }}
              >
                Report Finder
              </Link>
            </div>
            <div class="footer-link-items">
              <h2>{t("Solutions")}</h2>
              <Link
                to="/solutions"
                onClick={(e) => {
                  setActiveTab("solutions");
                }}
              >
                {t("Website")}
              </Link>
              <Link
                to="/solutions"
                onClick={(e) => {
                  setActiveTab("solutions");
                }}
              >
                {t("Software")}
              </Link>
              <Link
                to="/solutions"
                onClick={(e) => {
                  setActiveTab("solutions");
                }}
              >
                {t("IT Procurement")}
              </Link>
            </div>
          </div>
          <div className="footer-link-wrapper">
            <div class="footer-link-items">
              <h2>{t("Support")}</h2>
              <a href={"mailto:support@kwinformatics.com"}>
                <img src={imgMail} className="footer-logo" alt="EMail" />
                {t("Technical Support")}
              </a>
              <a href={"mailto:sales@kwinformatics.com"}>
                <img src={imgMail} className="footer-logo" alt="EMail" />
                {t("Sales Inquiry")}
              </a>
              <a href={"mailto:distributors@kwinformatics.com"}>
                <img src={imgMail} className="footer-logo" alt="EMail" />
                {t("Distributors")}
              </a>

              <div>
                <div>
                  <h3>
                    {" "}
                    <img src={imgCall} className="footer-logo2" alt="Call" />
                    <a
                      aria-label="Chat on WhatsApp"
                      href="https://wa.me/85255036147"
                    >
                      <img
                        src={imgWhatsapp}
                        className="footer-logo2"
                        alt="Whatsapp"
                      />
                    </a>{" "}
                    +852 5503 6147
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-link-wrapper"></div>
        </div>
        <section class="social-media">
          <div class="social-media-wrap">
            <h4>{t("OfficeRegion")}</h4>
            <h4>{t("HKOfficeAddress")}</h4>
          </div>
        </section>
      </div>
      <div className="footer-copyrightcontainer">
        <small class="website-rights">{t("CopyRightStatement")}</small>
      </div>
    </>
  );
}

export default Footer;
