import React from "react";
import "../App.css";
import Homebanner from "../components/Homebanner";
import Cards from "../components/Cards";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <>
      <Helmet>
        <title>KW Informatics|Home|Explore our latest offering</title>
        <meta
          name="description"
          content="Have ideas? Come to us! We Deliver Tailor-made IT Solutions, Grow Your Business Today!"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <Homebanner />
      <Cards />
      <Footer />
    </>
  );
}

export default Home;
