import React from "react";
import "../App.css";
import Footer from "../components/Footer";
import DomainChecker from "../components/DomainChecker";

function DomainServices() {
  return (
    <>
      <DomainChecker />
      <Footer />
    </>
  );
}

export default DomainServices;
