import React from "react";
import "./Cards.css";
import OtherCardItem from "./OtherCardItem";
import imgProgramming from "../images/img_programming.jpg";
import imgWebDesign from "../images/img_webdesign.jpg";
import imgSupport from "../images/img_ITSupport.jpeg";
import imgProcurement from "../images/img_ITProcurement.jpeg";
import imgProjectManagement from "../images/img_ProjectManagement.jpeg";
import { useTranslation } from "react-i18next";

function SolutionsCards() {
  const { t } = useTranslation();

  return (
    <>
      <div className="othercards">
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <OtherCardItem
                src={imgWebDesign}
                text={t(
                  "Our website development services encompass a wide range of modern web technologies and practices, including JavaScript, React, and Next.js."
                )}
                text1={t(
                  "We handle the entire process, from initial concept to deployment. This includes web hosting, domain name registration, and SSL certificate setup for a secure online presence."
                )}
                text2={t(
                  "Whether you need a sleek and informative website or a complex web application, we're well-equipped to bring your vision to life."
                )}
                label={t("Website Development")}
                path="/solutions"
              />
            </ul>
            <ul className="cards__items">
              <OtherCardItem
                src={imgProgramming}
                text={t(
                  "Our software development expertise extends to various domains, including C language, Java, and web and Windows applications."
                )}
                text1={t(
                  "We specialize in crafting custom software solutions to meet your specific needs, such as office automation, ERP systems, logistics management, and inventory control. With an emphasis on user-friendly interfaces and efficient performance, our software solutions streamline operations, increase productivity, and enhance your overall business efficiency."
                )}
                label={t("Software Development")}
                path="/solutions"
              />
            </ul>
            <ul className="cards__items">
              <OtherCardItem
                src={imgProcurement}
                text={t(
                  "Our IT procurement services are built on strong relationships with leading service providers and manufacturers. We're committed to sourcing the highest-quality hardware and software that align with your unique requirements. Whether you're looking for cutting-edge technology or cost-effective solutions, we'll help you secure the right IT assets for your organization."
                )}
                label={t("IT Procurement")}
                path="/solutions"
              />
            </ul>
            <ul className="cards__items">
              <OtherCardItem
                src={imgProjectManagement}
                text={t(
                  "Our IT project management expertise ensures the successful planning, execution, and delivery of your IT projects. We excel at coordinating resources, timelines, and budgets to bring your projects to fruition. Our rigorous project management methodologies guarantee on-time and on-budget delivery, helping you achieve your IT objectives."
                )}
                label={t("IT Project Management")}
                path="/solutions"
              />
            </ul>
            <ul className="cards__items">
              <OtherCardItem
                src={imgSupport}
                text={t(
                  "Our IT support services offer comprehensive technical assistance and solutions to keep your systems running smoothly. From troubleshooting issues to proactive system maintenance, we've got you covered. We pride ourselves on rapid issue resolution and providing ongoing support to optimize your IT environment, so you can focus on what you do best while leaving IT worries behind."
                )}
                label={t("IT Support")}
                path="/contactus"
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default SolutionsCards;
