import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";
import imgProgramming from "../images/img_programming.jpg";
import imgWebDesign from "../images/img_webdesign.jpg";
import imgSupport from "../images/img_ITSupport_resized.jpg";
import imgProcurement from "../images/img_ITProcurement_resized.jpg";
import imgProjectManagement from "../images/img_ProjectManagement_resized.jpg";
import imgTransLABtor from "../images/TransLabtorCard.png";
import imgReportFinder from "../images/ReportFinder.png";
import { useTranslation } from "react-i18next";

function Cards() {
  const { t } = useTranslation();

  return (
    <>
      <div className="cards">
        <h1>{t("Solutions and Services")}</h1>
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <CardItem
                src={imgWebDesign}
                text={t("WebDevDes")}
                label={t("Website Development")}
                path="/solutions"
              />
              <CardItem
                src={imgProgramming}
                text={t("softdevdes")}
                label={t("Software Development")}
                path="/solutions"
              />
            </ul>
            <ul className="cards__items">
              <CardItem
                src={imgProcurement}
                text={t("procurementdes")}
                label={t("IT Procurement")}
                path="/solutions"
              />
              <CardItem
                src={imgProjectManagement}
                text={t("ITPMdes")}
                label={t("IT Project Management")}
                path="/solutions"
              />
              <CardItem
                src={imgSupport}
                text={t("ITsupdes")}
                label={t("IT Support")}
                path="/contactus"
              />
            </ul>
          </div>
          <h1>{t("Products")}</h1>
          <div className="cards__containerProducts">
            <div className="cards__wrapper">
              <ul className="cards__items">
                <CardItem
                  src={imgTransLABtor}
                  text={t("TransLABtordes")}
                  label="TransLABtor"
                  path="/translabtor"
                />
                <CardItem
                  src={imgReportFinder}
                  text={t("ReportFinderdes")}
                  label="Report Finder"
                  path="/products"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
