import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutusPage from "./pages/Aboutus";
import { ContactusPage } from "./pages/Contactus";
import Home from "./pages/Home";
import ProductsPage from "./pages/Products";
import SolutionsPage from "./pages/Solutions";
import Navbar from "./components/Navbar";
import { ActiveTabContext } from "./components/ActiveTabContext";
import { useState } from "react";
import ScrollToTop from "./components/ToTop"; // Import the ScrollToTop component
import DomainServicesPage from "./pages/DomainService";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import TransLABtorPage from "./pages/TransLABtor";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVPKLGUraukVLq-rG5tVeGMBzSwHnDN8c",
  authDomain: "kwinfomatics-1409c.firebaseapp.com",
  projectId: "kwinfomatics-1409c",
  storageBucket: "kwinfomatics-1409c.appspot.com",
  messagingSenderId: "539365103750",
  appId: "1:539365103750:web:66c34a09859d506a021907",
  measurementId: "G-L8B9YZGZVW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [activeTab, setActiveTab] = useState("");

  return (
    <BrowserRouter>
      <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
        <Navbar />
        <ScrollToTop />
        <>
          <content>
            <Routes>
              <Route path="/" element={<Home />} />;
              <Route path="/aboutus" element={<AboutusPage />} />;
              <Route path="/contactus" element={<ContactusPage />} />;
              <Route path="/products" element={<ProductsPage />} />;
              <Route path="/solutions" element={<SolutionsPage />} />;
              <Route path="/domainservices" element={<DomainServicesPage />} />;
              <Route path="/translabtor" element={<TransLABtorPage />} />;
            </Routes>
          </content>
        </>
      </ActiveTabContext.Provider>
    </BrowserRouter>
  );
}

export default App;
