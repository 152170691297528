import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ActiveTabContext } from "./ActiveTabContext";
import "./Navbar.css";
import companyLogo from "../images/KWLogoBlueNoborder.png";
import menuIcon from "../images/menu.png";
import closeIcon from "../images/close.png";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown"; // Import the LanguageDropdown component

function Navbar() {
  const { t } = useTranslation();
  const [click, setClick] = useState(false);

  const { activeTab, setActiveTab } = useContext(ActiveTabContext);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link
            to="/"
            onClick={(e) => {
              setActiveTab("Home");
              closeMobileMenu();
            }}
          >
            <img
              src={companyLogo}
              width="240"
              height="160"
              className="logo"
              alt="KW Informatics Limited"
            />
          </Link>
          <div onClick={handleClick}>
            {click && (
              <>
                <img src={closeIcon} className="menu-icon" alt="Close" />
              </>
            )}
            {!click && <img src={menuIcon} className="menu-icon" alt="Menu" />}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/"
                className={
                  activeTab === "Home" ? "nav-linksActive" : "nav-links"
                }
                onClick={(e) => {
                  setActiveTab("Home");
                  closeMobileMenu();
                }}
              >
                {t("Home")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/solutions"
                className={
                  activeTab === "solutions" ? "nav-linksActive" : "nav-links"
                }
                onClick={(e) => {
                  setActiveTab("solutions");
                  closeMobileMenu();
                }}
              >
                {t("Solutions")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/products"
                className={
                  activeTab === "products" ? "nav-linksActive" : "nav-links"
                }
                onClick={(e) => {
                  setActiveTab("products");
                  closeMobileMenu();
                }}
              >
                {t("Products")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/aboutus"
                className={
                  activeTab === "aboutus" ? "nav-linksActive" : "nav-links"
                }
                onClick={(e) => {
                  setActiveTab("aboutus");
                  closeMobileMenu();
                }}
              >
                {t("About Us")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contactus"
                className={
                  activeTab === "contactus" ? "nav-linksActive" : "nav-links"
                }
                onClick={(e) => {
                  setActiveTab("contactus");
                  closeMobileMenu();
                }}
              >
                {t("Contact Us")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="lanuguagedropdown">
          <LanguageDropdown />
        </div>
      </nav>
    </>
  );
}

export default Navbar;
